import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { COLORS } from '../constants/style';
import SocialShare from '../components/SocialShare';

const Page = styled.div`
  padding-top: 120px;
  padding-bottom: 120px;
  text-align: center;

  width: 95%;
  max-width: 800px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;

  .video-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    margin-bottom: 2.5em;

    &:after {
      padding-top: 56.25%;
      display: block;
      content: '';
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .credit {
    display: inline-block;
    text-align: left;
    font-style: italic;
    margin: 0 auto;
    p {
      margin: 10px;
    }
  }

  h1 {
    font-weight: 900;
  }

  img {
    width: 100%;
  }

  article {
    text-align: justify;
    strong {
      color: ${COLORS.darkGold};
    }
    ol li {
      font-weight: 700;
      padding: 5px 0;
      color: #000;
    }
    .box {
      border: solid 1px ${COLORS.darkGold};
      padding: 0.8em;
      margin: 3em 0;

      h5 {
        font-weight: 900;
      }
      p {
        margin: 0;
      }
    }
    .booking-link {
      font-size: 1.2em;
      color: ${COLORS.darkGold};
    }
  }
`;

const desc =
  'The motivation behind this piece of devised theatre was to get into the corners of a film that presents new perspectives upon every viewing.\nIn VCE / EAL English, the difficulty is to see beyond the apparent, and to interpret with greater depth, the vision and conflicts presented.';

export default function RearWindowDramatisation({ location }) {
  return (
    <>
      <Helmet>
        <title>The Women of Rear Window: A Dramatisation</title>
        <meta property="og:url" content={location.href} />
        <meta
          property="og:title"
          content="The Women of Rear Window: A Dramatisation"
        />
        <meta property="og:description" content={desc} />
        <meta
          property="og:image"
          content={
            'https://www.datocms-assets.com/20206/1581624304-rw-sneak2.png'
          }
        />
      </Helmet>
      <Page>
        <h3 className="my-4">Edcellent Education In-School Performance</h3>
        <h1>
          The Women of Rear Window: <br />A Dramatisation
        </h1>
        <div className="credit">
          <p>Written by Cameron Sievers with Claire Nicholls</p>
          <p>Performed by Claire Nicholls</p>
          <p>Devised and Directed by Cameron Sievers</p>
        </div>
        <SocialShare url={location.href} />
        <div className="video-container">
          <iframe
            title="Rear Window Dramatisation"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/D_VPJNe7zI4"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <article>
          <p className="font-weight-bold mb-5">
            This is for all VCE English, EAL teachers at school aspiring to
            empower your students to master the film ‘Rear Window’ beyond what
            is available on the internet.
          </p>
          <h4>About The Women of Rear Window: A Dramatisation</h4>
          <p>
            The motivation behind this piece of devised theatre was to get into
            the corners of a film that presents new perspectives upon every
            viewing.
          </p>

          <p>
            In VCE / EAL English, the difficulty is to see beyond the apparent,
            and to interpret with greater depth, the vision and conflicts
            presented.
          </p>

          <p>
            In other words, you may have to answer a question such as the one
            below on your VCE English / EAL exam:{' '}
          </p>

          <blockquote>
            ‘The relationship between Jeff and Lisa is the real focus of the
            film. To what extent do you agree?’
          </blockquote>

          <p>
            In great art, there are no minor participants; merely a different
            focus attributed to each participant. The obvious, clearly, must be
            challenged. A largely non-verbal character has as much life and
            relevance as any protagonist. VCAA certainly thinks so…
          </p>

          <p>
            Perhaps the question of focus itself is the real focus of the film.
          </p>

          <p>This piece of devised theatre explores five characters: </p>
          <ol>
            <li>The Artist</li>
            <li>Miss Lonely-hearts</li>
            <li>Lisa Fremont</li>
            <li>Stella</li>
            <li>Miss Torso</li>
          </ol>

          <p>
            The aim of this performance is to give life to the ‘out of focus’
            and examine the key issues of:{' '}
          </p>
          <ol>
            <li>Gender and Society</li>
            <li>Power and Disempowerment</li>
            <li>Alienation and Privacy</li>
            <li>Voyeurism and Perspective</li>
            <li>Relationships and Change</li>
            <li>Metaphor and Symbolism</li>
            <li>Strength and Vulnerability</li>
          </ol>

          <p>
            We encourage depth of insight and contrarian thinking; with a direct
            link to <strong>VCE Text Response</strong> and{' '}
            <strong>Exam questions</strong>.
          </p>

          <div className="box">
            <h5>A Note on Devised Theatre:</h5>
            <p>
              It is important to recognise that nothing of what you are about to
              see existed previously. The wonderful challenge that devised
              theatre presents is in dealing with the true events of fiction and
              developing credible lives and stories from limited or fixed
              information. This is not a reproduction of scenes from the film;
              but is, rather, an informed speculation on characters that extends
              from the film.{' '}
            </p>
          </div>

          <h4>To make a booking request, please click here:</h4>
          <p className="booking-link">
            &rarr; &nbsp;{' '}
            <a href="https://docs.google.com/forms/d/1Hvl9TFp878gFSTja3pJvHiNnX3hF7kdJeJEdr6DD5VU/edit">
              The Women of Rear Window: Booking Request Form
            </a>
          </p>
        </article>
        <hr className="my-5" />
        <h2 className="text-gold font-weight-bold text-left">
          A sneak peak...
        </h2>
        <img
          src="https://www.datocms-assets.com/20206/1581624304-rw-sneak2.png"
          alt="sneak peek"
        />
        <br />
        <img
          src="https://www.datocms-assets.com/20206/1581624291-rw-sneak1.png"
          alt="sneak peek"
        />
      </Page>
    </>
  );
}
